import React from 'react';
import './App.css';
import ContactCard from './components/ContactCard.js'
import Footer from './components/Footer.js'
import { analyticsLinkSource } from './components/firebase.js'

function App() {
  analyticsLinkSource(getSlug(),'unloaded')
  return (
    <div className="App">
      <a href="https://icarushotsauce.ca/"><div className="banner fadeInOut">
        <h1><img src={'/Icarus-Banner.svg'} className="bannerImage" alt="ICARUS Hot Sauce" /></h1>
      </div></a>
      <body>
      <div className="hero fadeInOut">
        <img className="heroImg" src={'/Icarus-Whiskey-6-Map-Edit.JPG'} alt="ICARUS Hot Sauce Hero" />
      </div>
      <div className="flexy">
        <div className="textContainer fadeInOut">
          <h2 className="textContainer"><img src={'/Bison-Glyph.svg'} alt="ICARUS Hot Sauce" className="inlineGlyph" />  MB Made </h2> 
          <p className="textContainer">We make hand-crafted, small-batch, premium hot sauces in Morden, Manitoba. We use quality ingredients to produce sauce with rich flavour and heat.<br /><br />Follow us on <a href="https://www.instagram.com/icarushotsauce/">Instagram</a> to keep up with the hot sauce journey.</p>
        </div>
        <div className="textContainer textContainerBox fadeInOut">
          <img src={'/Icarus-Original.jpg'} alt="Icarus Hot Sauce" className="productImage" />
          <h2 className="textContainer"><img src={'/Icarus-Glyph.svg'} alt="" className="inlineGlyph" />  Icarus Original</h2>
          <h3 className="textContainer"><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" /><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" /> | $12</h3>
          <p className="textContainer"> Our flagship Helios habanero sauce is infused with Grand Marnier &amp; vermouth, boasting deep flavours with notes of citrus, garlic, &amp; ginger. Icarus is a versatile hot sauce perfect sauce for any meal.</p>
          <div className="buyButtonDiv"><a href="https://square.link/u/HHcQ0upE"><button className="mainButtonContrast" >Buy Icarus Original</button></a></div> 
        </div>
        <div className="textContainer textContainerBox fadeInOut">
          <img src={'/Icarus-Cherry-Boom-2.jpg'} alt="Icarus Cherry Boom" className="productImage" />
          <br /><h2 className="textContainer"><img src={'/Icarus-Glyph.svg'} alt="" className="inlineGlyph" />  Cherry Boom</h2> 
          <h3 className="textContainer"><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" /><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" /><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" />  | $15</h3>
          <p className="textContainer"> Our limited edition cherry bomb pepper &amp; Helios habanero "Cherry Boom" hot sauce is for those who want intense heat without sacrificing rich flavour. Only a limited number are produced each year - get it before it's gone.<br /><br />Go ahead, fly close to the sun.</p>
          <div className="buyButtonDiv"><a href="https://square.link/u/S42Cp1C1"><button className="mainButtonContrast" >Buy Cherry Boom</button></a></div>
        </div>
        <div className="textContainer textContainerBox fadeInOut">
          <img src={'/Icarus-Combo.jpg'} alt="Icarus Cherry Boom" className="productImage" />
          <br /><h2 className="textContainer"><img src={'/Icarus-Glyph.svg'} alt="" className="inlineGlyph" />  The Perfect Pair</h2> 
          <h3 className="textContainer"><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" /><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" /><img src={'/Hot.svg'} alt="" className="inlineGlyphSmall" />  | $27</h3>
          <p className="textContainer"> Cant decide? Order The Perfect Pair and try both Icarus Original and the limited edition Cherry Boom hot sauce.</p>
          <div className="buyButtonDiv"><a href="https://square.link/u/vhuKxSxO"><button className="mainButtonContrast" >Buy the Pair</button></a></div>
        </div>
        <div className="textContainer fadeInOut">
          <br /><h2 className="textContainer"><img src={'/Delivery.svg'} alt="Icarus Delivery" className="inlineGlyph" />  Shipping &amp; Delivery</h2> 
          <p className="textContainer">We offer free delivery in the Morden/Winkler area, and will ship elsewhere within Canada. If you would prefer to pay with cash, debit, or eTransfer, please send us a message. If you have any questions about our sauce or how to purchase, don't hesitate to <a href="mailto:hello@icarushotsauce.com">contact us!</a></p>          
        </div>
        <div className="textContainer fadeInOut">
          <br /><h2 className="textContainer"><img src={'/Instagram.svg'} alt="Icarus Instagram" className="inlineGlyph" />  Follow us on Instagram</h2> 
          <p className="textContainer">Check out ICARUS on <a href="https://www.instagram.com/icarushotsauce/">Instagram</a> and watch for updates and giveaways. <a href="https://www.instagram.com/icarushotsauce/">@icarushotsauce</a></p>          
        </div>
      </div>
      </body>
      <ContactCard showForm={true} />
      <Footer />
    </div>
  );
}

function getSlug() {
  const slug = sanitizeString(window.location.pathname);
  if(hasSlug() && !shouldIgnoreSlug(slug)){
    return slug
  }else{
    return false
  }
}

function sanitizeString(str){
  str = str.replace(/[^a-z0-9áéíóúñü ,_-]/gim,"");
  return str.trim();
}

function hasSlug() {
  //return 'beta' === getParams(window.location, 'beta');
  //return '/beta' === window.location.pathname;
  return window.location.pathname.length > 1;
}

function shouldIgnoreSlug(slug) {
  switch(slug) {
    case 'beta':
      return true;
    case 'api':
      return true;
    case 'add':
      return true;
    default:
      return false;
  }
}

export default App;
