import React, {Component, useState} from 'react';
import {ClipLoader} from 'react-spinners';
import { newContact, newMailingMember } from '../components/firebase.js'

function ContactCard(props){
    const [emailBlur, setEmailBlur] = useState(false);
    const [email, setEmail] = useState('');
    const [formState, setFormState] = useState('new');
    
    return(
        <div className="ContactCard fadeInOut">
            {/* <h2>Contact Us</h2>
            <p className="footer">280-530 Kenaston Blvd. Winnipeg, MB, R3N 1Z4
            </p>
            <p className="footer"> 204.XXX.XXXX
            </p> */}
            {props.showForm ? 
                formState === 'new' || formState === 'loading' ?
                <div className="formContainer">
                    {showLoaderScreen((formState === 'loading'))}
                    <h2>Sign up for the mailing list</h2>
                    <label className={emailBlur && !validateEmail(email) ? 'labelRed' : 'label'}>Email*
                        <input onBlur={() => setEmailBlur(true)} onChange={(e) => setEmail(e.target.value)} className="StripeElement stripeCustField" type="text" name="email" placeholder="name@example.com" value={email} />
                    </label>
                    <button onClick={() => addMailing(email, setFormState)} className="mainButtonContrast" disabled={!validateEmail(email)}>Sign Up</button>
                </div>
                :
                <div className="formContainer">
                    {formState === 'sent' ? 
                        <p>Thanks for signing up! Watch your inbox for updates.</p>
                    :formState === 'failed' ?
                        <div>
                            <p>Something went wrong... please try again.</p>
                            <button onClick={() => setFormState('new')} className="mainButtonContrast" >Try Again</button>
                        </div>
                    :
                        null
                    }
                </div>
            : 
               <a href="/contact"><button className="mainButtonContrast" >Send us a Message</button></a> 
            }
            <p className="footer">- Or -<br /><br /><a href="mailto:hello@icarushotsauce.com"><button className="mainButtonContrast">Send us an email</button></a></p>
            <div className="spacer" />
        </div>
    )
}

function send(name, email, phone, message, setFormState){
    setFormState('loading')
    const contact = {
        name:name,
        email:email,
        phone:phone,
        message:message
    }
    newContact(contact)
    .then(result =>{
        if(result && result.id && result.id.length > 0){
            setFormState('sent')
        }else{
            setFormState('failed')
        }
    })
    .catch(error => {
        console.log(error)
        setFormState('failed')
    })
}

function addMailing(email, setFormState){
    setFormState('loading')
    const contact = {
        email:email
    }
    newMailingMember(contact)
    .then(result =>{
        if(result && result.id && result.id.length > 0){
            setFormState('sent')
        }else{
            setFormState('failed')
        }
    })
    .catch(error => {
        console.log(error)
        setFormState('failed')
    })
}

function validate(name, email){
    return (validateName(name) && validateEmail(email))
}
function validateName(name){
    if(name && name.length > 1){
        return true
    }else{
        return false
    }
}
function validateEmail(email){
    if(email && email.length > 3 && email.includes('@') && email.includes('.')){
        return true
    }else{
        return false
    }
}


function showLoaderScreen(show){
    if(show){
      return (<div className="menuItemDetail-back App" ><div className="loaderOverlay">{loader()}</div></div>)
    }else{
      return null
    }
  }

function loader() {
    return (<ClipLoader sizeUnit={"px"} size={100} color={'#000826;'} loading={true} />)
}

export default ContactCard