import firebase from "firebase/app";


// Required for side-effects
require("firebase/firestore");
require("firebase/analytics");

// Initialize Firebase
var config = {
    apiKey: "AIzaSyBfAcRNoF44MWyN9qtrud4NNluCzVGnLL0",
    authDomain: "icarus-10d3c.firebaseapp.com",
    databaseURL: "https://icarus-10d3c.firebaseio.com",
    projectId: "icarus-10d3c",
    storageBucket: "icarus-10d3c.appspot.com",
    messagingSenderId: "515712885131",
    appId: "1:515712885131:web:ea41a030f90b1231d28f8e"
  };
  console.log(firebase)
if (!firebase.apps || !firebase.apps.length) {
    firebase.initializeApp(config);
 }
var db = firebase.firestore();
var cms = db.collection('cms')
var memberContent = db.collection('memberContent')


export function analyticsPageView(page, unloaded){
    if (process.browser && unloaded==='unloaded') {
        // Client-side-only code
        firebase.analytics().logEvent('PageView_'+page);
        console.log(page)
      }
    }

export function analyticsLinkSource(slug, unloaded){
    if (process.browser && unloaded==='unloaded') {
        // Client-side-only code
        firebase.analytics().logEvent('LinkSource_'+slug);
        console.log(slug)
        }
    }

export function newContact(data){
    data['date'] = firebase.firestore.FieldValue.serverTimestamp()
    data['sent'] = false
    return db.collection('contact').add(data)
    .then(docRef =>{
        return {id:docRef.id}
    })
}

export function newMailingMember(data){
    data['date'] = firebase.firestore.FieldValue.serverTimestamp()
    data['sent'] = false
    return db.collection('mailingList').add(data)
    .then(docRef =>{
        return {id:docRef.id}
    })
}