import React, {Component} from 'react';

function Footer(){
    return(
        <footer>
          <img src="/Icarus-Glyph.svg" alt="ICARUS Hot Sauce" className="footerBrand" />
          <div className="footerInfo">
            <p className="footer textLeft"><a href="mailto:hello@icarushotsauce.com">hello@icarushotsauce.com</a></p>
          </div>
          <div className="lightLine" />
          <p className="footer textRight">© 2020 ICARUS Hot Sauce | <span className="powered">Powered by <a className="powered" href="https://foxbit.ca">Foxbit</a></span></p>
        </footer>
    )
}

export default Footer